<template>
  <svg
    :height="`${height}px` || '16px'"
    :width="`${width}px` || '21px'"
    viewBox="0 0 21 16"
  >
    <path d="M11.8779 16V0L20.4865 8L11.8779 16Z" :fill="color" />
    <path
      d="M13.8344 5.57578H0.138992V10.4243H13.8344V5.57578Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    }
  }
}
</script>
