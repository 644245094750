<template>
  <svg viewBox="0 0 18 12" :height="`${height}px` || '12px'" :width="`${width}px` || '18px'" :class="`fill-current stroke-current text-${color}`">
    <path d="M1.125 1.125H16.875M1.125 6H16.875M1.125 10.875H16.875" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '18'
    }
  }
}
</script>
