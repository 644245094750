<template>
  <svg
    :height="`${height}px` || '20px'"
    :width="`${width}px` || '20px'"
    viewBox="0 0 16 16"
  >
    <path
      d="M8.9 15H7.2C7 15 6.8 14.9 6.8 14.7L6.6 13.8C6 13.7 5.5 13.4 5 13.1L4.2 13.6C4 13.7 3.8 13.7 3.7 13.6L2.5 12.4C2.4 12.3 2.3 12 2.5 11.9L3 11.1C2.7 10.6 2.4 10 2.3 9.4L1.3 9.2C1.2 9.2 1 9 1 8.8V7.1C1 6.9 1.1 6.7 1.3 6.7L2.2 6.5C2.3 5.9 2.6 5.4 2.9 4.9L2.4 4.1C2.3 3.9 2.3 3.7 2.4 3.6L3.6 2.4C3.7 2.3 4 2.2 4.1 2.4L4.9 2.9C5.4 2.6 6 2.4 6.5 2.2L6.7 1.3C6.7 1.1 6.9 1 7.1 1H8.9C9.1 1 9.3 1.1 9.3 1.3L9.5 2.2C10.1 2.3 10.6 2.6 11.1 2.9L11.9 2.3C12.1 2.2 12.3 2.2 12.4 2.3L13.6 3.5C13.7 3.6 13.8 3.9 13.6 4L13.1 4.8C13.4 5.3 13.6 5.9 13.8 6.4L14.7 6.6C14.9 6.6 15 6.8 15 7V8.8C15 9 14.9 9.2 14.7 9.2L13.9 9.4C13.8 10 13.5 10.5 13.2 11L13.7 11.8C13.8 12 13.8 12.2 13.7 12.3L12.5 13.5C12.4 13.6 12.1 13.7 12 13.5L11.2 13C10.7 13.3 10.1 13.5 9.6 13.7L9.4 14.6C9.3 14.9 9.1 15 8.9 15ZM5 12.7H5.1C5.6 13 6.2 13.3 6.9 13.4C7 13.4 7 13.5 7.1 13.6L7.3 14.7H8.9L9.1 13.6C9.1 13.5 9.2 13.5 9.3 13.4C9.9 13.3 10.5 13 11 12.7C11.1 12.7 11.2 12.7 11.2 12.7L12.1 13.3L13.3 12.1L12.7 11.2C12.7 11.1 12.7 11.1 12.7 11C13 10.5 13.3 9.9 13.4 9.2C13.4 9.1 13.5 9.1 13.6 9L14.7 8.8V7.1L13.6 6.9C13.5 6.9 13.5 6.8 13.4 6.7C13.3 6.1 13 5.5 12.7 4.9C12.7 4.8 12.7 4.8 12.7 4.7L13.3 3.8L12.1 2.6L11.2 3.2C11.1 3.2 11 3.2 11 3.2C10.5 2.9 9.9 2.7 9.3 2.5C9.2 2.5 9.2 2.4 9.1 2.3L8.9 1.3H7.2L7 2.4C7 2.5 6.9 2.5 6.9 2.5C6.2 2.7 5.6 2.9 5.1 3.3C5 3.3 4.9 3.3 4.9 3.3L4 2.7L2.8 3.9L3.4 4.8C3.4 4.9 3.4 5 3.4 5C3 5.5 2.8 6.1 2.6 6.8C2.6 6.9 2.5 6.9 2.4 7L1.4 7.2V8.8L2.5 9C2.6 9 2.6 9.1 2.7 9.2C2.8 9.8 3 10.4 3.4 11C3.4 11.1 3.4 11.2 3.4 11.2L2.8 12.1L4 13.3L4.9 12.7C4.9 12.7 4.9 12.7 5 12.7Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M9.90001 11.8C9.80001 11.8 9.70001 11.7 9.70001 11.6C9.70001 11.5 9.70001 11.5 9.80001 11.4C10 11.3 10.1 11.2 10.3 11.1C10.4 11 10.5 11.1 10.6 11.2C10.7 11.3 10.6 11.4 10.6 11.5C10.3 11.6 10.1 11.7 9.90001 11.8Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M8.10002 12.2C5.80002 12.2 3.90002 10.3 3.90002 8C3.90002 5.7 5.80002 3.8 8.10002 3.8C10.4 3.8 12.3 5.7 12.3 8C12.3 8.1 12.3 8.1 12.3 8.2C12.3 8.3 12.2 8.4 12.1 8.4C12 8.4 11.9 8.3 11.9 8.2C11.9 8.1 11.9 8.1 11.9 8C11.9 5.9 10.2 4.2 8.10002 4.2C6.00002 4.2 4.20002 5.9 4.20002 8C4.20002 10.1 6.00002 11.8 8.10002 11.8C8.20002 11.8 8.20002 11.8 8.30002 11.8C8.40002 11.8 8.50002 11.9 8.50002 12C8.50002 12.1 8.40002 12.2 8.30002 12.2C8.20002 12.2 8.10002 12.2 8.10002 12.2Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M11.4 10.5C11.3 10.5 11.2 10.4 11.2 10.3V10.2C11.3 10 11.4 9.9 11.5 9.7C11.5 9.6 11.7 9.6 11.8 9.6C11.9 9.6 11.9 9.8 11.9 9.9C11.8 10.1 11.7 10.3 11.6 10.5C11.5 10.5 11.4 10.5 11.4 10.5Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M9.2 9C9.1 9 9 8.9 9 8.8C9 8.7 9 8.7 9.1 8.6L9.9 8L9.1 7.3C9 7.2 9 7.1 9.1 7C9.2 6.9 9.3 6.9 9.4 7L10.4 7.8C10.5 7.9 10.5 8 10.4 8.1L9.4 8.9C9.3 9 9.3 9 9.2 9Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M6.89998 9H6.79998L5.79998 8.2C5.69998 8.1 5.69998 7.9 5.79998 7.8L6.79998 7C6.89998 6.9 6.99998 6.9 7.09998 7C7.19998 7.1 7.19998 7.2 7.09998 7.3L6.19998 8L6.99998 8.7C7.09998 8.8 7.09998 8.9 6.99998 9C6.99998 9 6.99998 9 6.89998 9Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M7.40001 10C7.20001 10 7.20001 9.8 7.20001 9.7L8.60001 6C8.60001 5.9 8.80001 5.8 8.90001 5.9C8.90001 6 9.00001 6.1 8.90001 6.2L7.60001 9.9C7.60001 10 7.50001 10 7.40001 10Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    }
  }
}
</script>
