<template>
  <svg :height="`${height}px` || '20px'" :width="`${width}px` || '20px'" :class="`fill-current text-${color} stroke-current`" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.3042 12H36.6254C36.4986 12 36.377 12.0506 36.2874 12.1407C36.1978 12.2308 36.1475 12.3531 36.1475 12.4805C36.1458 12.5441 36.1569 12.6073 36.1804 12.6664C36.2038 12.7254 36.2389 12.7791 36.2837 12.824C36.3284 12.869 36.3817 12.9043 36.4405 12.9279C36.4992 12.9514 36.5622 12.9627 36.6254 12.961H38.3042C38.6855 12.9642 39.0501 13.1188 39.3187 13.3911C39.5872 13.6634 39.7379 14.0313 39.7379 14.4148V16.6325H8.95585V14.4148C8.95583 14.0313 9.10646 13.6634 9.37498 13.3911C9.64351 13.1188 10.0082 12.9642 10.3895 12.961H34.5177C34.5809 12.9627 34.6438 12.9514 34.7025 12.9279C34.7613 12.9043 34.8147 12.869 34.8594 12.824C34.9041 12.7791 34.9392 12.7254 34.9626 12.6664C34.986 12.6073 34.9973 12.5441 34.9956 12.4805C34.9956 12.3531 34.9452 12.2308 34.8556 12.1407C34.7659 12.0506 34.6444 12 34.5177 12H10.3895C9.75023 12.0161 9.14246 12.2828 8.69602 12.7431C8.24957 13.2035 7.9998 13.8211 8 14.4641V19.7741C8 19.9016 8.05039 20.0238 8.14001 20.1139C8.22964 20.204 8.35118 20.2546 8.47792 20.2546C8.60467 20.2546 8.72621 20.204 8.81584 20.1139C8.90546 20.0238 8.95585 19.9016 8.95585 19.7741V17.6427H39.7379V30.0493C39.7378 30.1779 39.7878 30.3014 39.8771 30.3935C39.9663 30.4855 40.0879 30.5389 40.2158 30.5421C40.2796 30.5421 40.3427 30.5293 40.4015 30.5044C40.4603 30.4795 40.5136 30.443 40.5581 30.397C40.6027 30.3511 40.6376 30.2967 40.6609 30.2369C40.6842 30.1772 40.6953 30.1134 40.6936 30.0493V14.3778C40.672 13.7499 40.4125 13.154 39.9684 12.712C39.5242 12.27 38.9289 12.0153 38.3042 12Z"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M40.2158 31.5154C40.09 31.5185 39.9702 31.5701 39.8812 31.6596C39.7923 31.749 39.741 31.8694 39.7379 31.9959V33.2279C39.7379 33.6102 39.5868 33.9769 39.3179 34.2472C39.049 34.5175 38.6844 34.6694 38.3042 34.6694H10.3895C10.0093 34.6694 9.64461 34.5175 9.37573 34.2472C9.10686 33.9769 8.95585 33.6102 8.95585 33.2279V21.7207C8.95585 21.5933 8.90546 21.4711 8.81584 21.381C8.72621 21.2909 8.60467 21.2402 8.47792 21.2402C8.35118 21.2402 8.22964 21.2909 8.14001 21.381C8.05039 21.4711 8 21.5933 8 21.7207V33.2279C8.01553 33.8602 8.27231 34.4622 8.71711 34.9094C9.16191 35.3566 9.76069 35.6148 10.3895 35.6304H38.3042C38.933 35.6148 39.5318 35.3566 39.9766 34.9094C40.4214 34.4622 40.6781 33.8602 40.6936 33.2279V31.9959C40.6936 31.8685 40.6433 31.7462 40.5537 31.6561C40.4641 31.566 40.3425 31.5154 40.2158 31.5154Z"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M14.9234 22.4353C14.88 22.3895 14.8277 22.353 14.7697 22.3281C14.7118 22.3031 14.6494 22.2903 14.5864 22.2903C14.5234 22.2903 14.4611 22.3031 14.4032 22.3281C14.3453 22.353 14.293 22.3895 14.2495 22.4353L11.3697 25.3183C11.2827 25.4119 11.2344 25.5352 11.2344 25.6633C11.2344 25.7914 11.2827 25.9147 11.3697 26.0082L14.2127 28.8666C14.3071 28.9518 14.429 28.9999 14.5558 29.0021C14.6794 29.0016 14.7979 28.953 14.8866 28.8666C14.9764 28.7744 15.0268 28.6506 15.0268 28.5216C15.0268 28.3926 14.9764 28.2687 14.8866 28.1766L12.4358 25.7126L14.9724 23.1622C15.018 23.1185 15.0544 23.0659 15.0792 23.0077C15.104 22.9495 15.1167 22.8868 15.1167 22.8234C15.1167 22.7601 15.104 22.6974 15.0792 22.6392C15.0544 22.5809 15.018 22.5284 14.9724 22.4846L14.9234 22.4353Z"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M21.3692 28.8666C21.4636 28.9518 21.5854 28.9999 21.7123 29.0021C21.8358 29.0016 21.9544 28.953 22.0432 28.8666L24.8861 26.0082C24.9731 25.9147 25.0215 25.7914 25.0215 25.6633C25.0215 25.5352 24.9731 25.4119 24.8861 25.3183L22.0064 22.4353C21.9629 22.3895 21.9106 22.353 21.8527 22.3281C21.7947 22.3031 21.7324 22.2903 21.6694 22.2903C21.6064 22.2903 21.544 22.3031 21.4861 22.3281C21.4282 22.353 21.3759 22.3895 21.3324 22.4353C21.2434 22.5254 21.1934 22.6472 21.1934 22.7741C21.1934 22.9011 21.2434 23.0229 21.3324 23.1129L23.869 25.6633L21.4182 28.1273C21.3284 28.2194 21.2781 28.3433 21.2781 28.4723C21.2781 28.6013 21.3284 28.7251 21.4182 28.8173L21.3692 28.8666Z"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M16.4306 30.6283C16.5268 30.6291 16.6208 30.5999 16.6998 30.5447C16.7788 30.4896 16.8389 30.4112 16.8718 30.3203L20.2784 21.3142C20.3239 21.1949 20.3204 21.0623 20.2687 20.9457C20.217 20.829 20.1213 20.7377 20.0027 20.692C19.8841 20.6462 19.7522 20.6497 19.6362 20.7017C19.5201 20.7537 19.4294 20.8499 19.3839 20.9692L15.9772 29.9877C15.9369 30.1071 15.9436 30.2376 15.996 30.3522C16.0484 30.4668 16.1426 30.5568 16.2591 30.6037C16.3156 30.6157 16.3741 30.6157 16.4306 30.6037V30.6283Z"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M10.1074 14.7844C10.1074 15.0695 10.1915 15.3482 10.349 15.5852C10.5065 15.8223 10.7305 16.007 10.9924 16.1161C11.2544 16.2252 11.5427 16.2538 11.8208 16.1982C12.099 16.1426 12.3544 16.0052 12.5549 15.8037C12.7554 15.6021 12.892 15.3452 12.9473 15.0656C13.0026 14.786 12.9742 14.4961 12.8657 14.2327C12.7572 13.9693 12.5734 13.7442 12.3377 13.5858C12.1019 13.4274 11.8247 13.3429 11.5411 13.3429C11.1609 13.3429 10.7962 13.4948 10.5273 13.7651C10.2584 14.0354 10.1074 14.4021 10.1074 14.7844ZM12.019 14.7844C12.0207 14.848 12.0095 14.9112 11.9861 14.9702C11.9626 15.0293 11.9276 15.083 11.8828 15.1279C11.8381 15.1729 11.7847 15.2082 11.726 15.2317C11.6673 15.2553 11.6043 15.2666 11.5411 15.2649C11.4144 15.2649 11.2928 15.2142 11.2032 15.1241C11.1136 15.034 11.0632 14.9118 11.0632 14.7844C11.0632 14.657 11.1136 14.5347 11.2032 14.4446C11.2928 14.3545 11.4144 14.3039 11.5411 14.3039C11.6043 14.3022 11.6673 14.3135 11.726 14.337C11.7847 14.3606 11.8381 14.3959 11.8828 14.4409C11.9276 14.4858 11.9626 14.5394 11.9861 14.5985C12.0095 14.6576 12.0207 14.7208 12.019 14.7844Z"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M13.8204 14.7844C13.8179 15.073 13.901 15.3558 14.059 15.5967C14.2169 15.8377 14.4426 16.0259 14.7073 16.1375C14.972 16.249 15.2637 16.2789 15.5454 16.2232C15.827 16.1675 16.0858 16.0287 16.2887 15.8247C16.4917 15.6206 16.6297 15.3605 16.6851 15.0773C16.7405 14.7941 16.7108 14.5008 16.5999 14.2347C16.4889 13.9685 16.3017 13.7416 16.062 13.5828C15.8224 13.424 15.5411 13.3405 15.2541 13.3429C14.8739 13.3429 14.5092 13.4948 14.2403 13.7651C13.9714 14.0355 13.8204 14.4021 13.8204 14.7844ZM15.732 14.7844C15.7337 14.848 15.7225 14.9112 15.6991 14.9703C15.6757 15.0294 15.6405 15.083 15.5958 15.128C15.5511 15.1729 15.4977 15.2082 15.4389 15.2318C15.3802 15.2553 15.3174 15.2666 15.2541 15.2649C15.1274 15.2649 15.0058 15.2143 14.9161 15.1242C14.8265 15.0341 14.7762 14.9119 14.7762 14.7844C14.7762 14.657 14.8265 14.5348 14.9161 14.4447C15.0058 14.3546 15.1274 14.3039 15.2541 14.3039C15.3174 14.3022 15.3802 14.3135 15.4389 14.3371C15.4977 14.3606 15.5511 14.3959 15.5958 14.4409C15.6405 14.4859 15.6757 14.5395 15.6991 14.5986C15.7225 14.6576 15.7337 14.7209 15.732 14.7844Z"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M17.5333 14.7844C17.5308 15.073 17.6139 15.3558 17.7718 15.5967C17.9298 15.8377 18.1555 16.0259 18.4202 16.1375C18.6849 16.249 18.9766 16.2789 19.2583 16.2232C19.5399 16.1675 19.7987 16.0287 20.0016 15.8247C20.2046 15.6206 20.3426 15.3605 20.398 15.0773C20.4534 14.7941 20.4236 14.5008 20.3127 14.2347C20.2017 13.9685 20.0145 13.7416 19.7749 13.5828C19.5352 13.424 19.254 13.3405 18.967 13.3429C18.5867 13.3429 18.222 13.4948 17.9531 13.7651C17.6843 14.0355 17.5333 14.4021 17.5333 14.7844ZM19.4571 14.7844C19.4596 14.8824 19.4329 14.9789 19.3806 15.0615C19.3282 15.1442 19.2525 15.2092 19.1632 15.2484C19.0739 15.2876 18.9749 15.2991 18.8791 15.2815C18.7832 15.2639 18.6947 15.218 18.6249 15.1496C18.5551 15.0812 18.5072 14.9934 18.4873 14.8975C18.4674 14.8016 18.4763 14.7019 18.513 14.6111C18.5497 14.5203 18.6126 14.4426 18.6934 14.3879C18.7743 14.3332 18.8695 14.304 18.967 14.3039C19.0307 14.3023 19.0942 14.3135 19.1536 14.3369C19.213 14.3603 19.2672 14.3954 19.3129 14.4402C19.3585 14.485 19.3948 14.5385 19.4196 14.5977C19.4444 14.6568 19.4572 14.7203 19.4571 14.7844Z"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M29.4205 22.9405H32.4226C32.6229 22.9591 32.8248 22.9355 33.0154 22.8712C33.2061 22.8069 33.3814 22.7033 33.5299 22.5671C33.6785 22.4308 33.7973 22.265 33.8785 22.08C33.9597 21.895 34.0016 21.695 34.0016 21.4928C34.0016 21.2907 33.9597 21.0907 33.8785 20.9057C33.7973 20.7208 33.6785 20.5549 33.5299 20.4187C33.3814 20.2824 33.2061 20.1788 33.0154 20.1145C32.8248 20.0502 32.6229 20.0266 32.4226 20.0452H29.4205C29.0615 20.0786 28.7279 20.2455 28.485 20.5133C28.2421 20.7811 28.1074 21.1304 28.1074 21.4928C28.1074 21.8553 28.2421 22.2046 28.485 22.4724C28.7279 22.7402 29.0615 22.9072 29.4205 22.9405ZM29.4205 21.0062H32.4226C32.5378 21.0247 32.6426 21.0838 32.7183 21.173C32.794 21.2622 32.8356 21.3756 32.8356 21.4928C32.8356 21.6101 32.794 21.7235 32.7183 21.8127C32.6426 21.9019 32.5378 21.961 32.4226 21.9795H29.4205C29.3053 21.961 29.2005 21.9019 29.1248 21.8127C29.0491 21.7235 29.0075 21.6101 29.0075 21.4928C29.0075 21.3756 29.0491 21.2622 29.1248 21.173C29.2005 21.0838 29.3053 21.0247 29.4205 21.0062Z"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M29.4201 26.7843H37.3484C37.5367 26.7843 37.7231 26.747 37.8971 26.6746C38.071 26.6022 38.2291 26.496 38.3622 26.3621C38.4953 26.2283 38.6009 26.0694 38.673 25.8945C38.745 25.7196 38.7821 25.5321 38.7821 25.3428C38.7821 25.1535 38.745 24.9661 38.673 24.7912C38.6009 24.6163 38.4953 24.4574 38.3622 24.3236C38.2291 24.1897 38.071 24.0835 37.8971 24.0111C37.7231 23.9386 37.5367 23.9014 37.3484 23.9014H29.4201C29.0399 23.9014 28.6752 24.0532 28.4063 24.3236C28.1374 24.5939 27.9863 24.9605 27.9863 25.3428C27.9863 25.7251 28.1374 26.0918 28.4063 26.3621C28.6752 26.6325 29.0399 26.7843 29.4201 26.7843ZM29.4201 24.8624H37.3484C37.4742 24.8655 37.5939 24.9171 37.6829 25.0065C37.7718 25.096 37.8232 25.2164 37.8263 25.3428C37.8263 25.4703 37.7759 25.5925 37.6863 25.6826C37.5967 25.7727 37.4751 25.8233 37.3484 25.8233H29.4201C29.2934 25.8233 29.1717 25.7727 29.0821 25.6826C28.9925 25.5925 28.9422 25.4703 28.9422 25.3428C28.9453 25.2164 28.9966 25.096 29.0856 25.0065C29.1745 24.9171 29.2943 24.8655 29.4201 24.8624Z"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M29.4206 30.6406H34.9839C35.1721 30.6406 35.3586 30.6033 35.5325 30.5309C35.7065 30.4585 35.8645 30.3523 35.9977 30.2184C36.1308 30.0846 36.2364 29.9257 36.3084 29.7508C36.3805 29.5759 36.4176 29.3884 36.4176 29.1991C36.4176 29.0099 36.3805 28.8224 36.3084 28.6475C36.2364 28.4727 36.1308 28.3137 35.9977 28.1799C35.8645 28.046 35.7065 27.9398 35.5325 27.8674C35.3586 27.795 35.1721 27.7577 34.9839 27.7577H29.4083C29.0281 27.7577 28.6634 27.9096 28.3945 28.1799C28.1256 28.4502 27.9746 28.8168 27.9746 29.1991C27.9746 29.5815 28.1256 29.9481 28.3945 30.2184C28.6634 30.4888 29.0281 30.6406 29.4083 30.6406H29.4206ZM29.4206 28.7187H34.9839C35.1106 28.7187 35.2321 28.7693 35.3218 28.8594C35.4114 28.9495 35.4618 29.0717 35.4618 29.1991C35.4587 29.3256 35.4073 29.446 35.3183 29.5355C35.2294 29.6249 35.1096 29.6765 34.9839 29.6797H29.4083C29.2825 29.6765 29.1628 29.6249 29.0738 29.5355C28.9849 29.446 28.9335 29.3256 28.9304 29.1991C28.9304 29.135 28.9431 29.0715 28.9679 29.0124C28.9927 28.9533 29.029 28.8998 29.0747 28.855C29.1203 28.8102 29.1745 28.7751 29.2339 28.7516C29.2933 28.7282 29.3568 28.717 29.4206 28.7187Z"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '48'
    },
    height: {
      type: String,
      default: '48'
    }
  }
}
</script>
