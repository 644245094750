export default {
  name: 'accordion',
  components: {
    AccordionItem: () => import(/* webpackChunkName: "accordion" */ '@/components/accordion/AccordionItem')
  },
  props: {
    content: {
      type: Array
    },
    multiple: {
      type: Boolean,
      default: false
    },
    additionalClass: {
      type: String
    }
  },
  data() {
    return {
      groupId: null
    }
  },
  mounted() {
    this.groupId = this.$el.id
  }
}
