<template>
  <svg :height="`${height}px`" :width="`${width}px`" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 2.25C5.27344 2.25 2.25 5.27344 2.25 9C2.25 12.7266 5.27344 15.75 9 15.75C12.7266 15.75 15.75 12.7266 15.75 9C15.75 5.27344 12.7266 2.25 9 2.25Z"
      :stroke="`${color}`"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path d="M9 4.5V9.5625H12.375" :stroke="`${color}`" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#FFFFFF'
    },
    width: {
      type: String,
      default: '18'
    },
    height: {
      type: String,
      default: '18'
    }
  }
}
</script>
