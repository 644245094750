<template>
  <svg :height="`${height}px` || '20px'" :width="`${width}px` || '20px'" :class="`fill-current text-${color}`" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M65.4746 36.6885C66.0678 36.6885 66.5488 36.2163 66.5488 35.6338V24.1641C66.5488 22.4194 65.1031 21 63.3262 21H24.2246C22.4476 21 21.002 22.4194 21.002 24.1641V71.8359C21.002 73.5806 22.4476 75 24.2246 75H63.3262C65.1031 75 66.5488 73.5806 66.5488 71.8359V64.0658C66.5488 63.4833 66.0678 63.0112 65.4746 63.0112C64.8814 63.0112 64.4004 63.4833 64.4004 64.0658V71.8359C64.4004 72.4175 63.9185 72.8906 63.3262 72.8906H24.2246C23.6323 72.8906 23.1504 72.4175 23.1504 71.8359V24.1641C23.1504 23.5825 23.6323 23.1094 24.2246 23.1094H63.3262C63.9185 23.1094 64.4004 23.5825 64.4004 24.1641V35.6338C64.4004 36.2163 64.8814 36.6885 65.4746 36.6885Z"
    />
    <path
      d="M43.7734 26.6953C38.324 26.6953 33.8906 31.0481 33.8906 36.3984C33.8906 39.1741 35.0841 41.6808 36.9932 43.451C37.029 43.4886 37.067 43.5241 37.1086 43.5565C38.8672 45.1363 41.2076 46.1015 43.7733 46.1015C46.3391 46.1015 48.6795 45.1362 50.4381 43.5565C50.4797 43.5241 50.5177 43.4885 50.5535 43.451C52.4628 41.6808 53.6562 39.1741 53.6562 36.3984C53.6562 31.0481 49.2228 26.6953 43.7734 26.6953ZM43.7734 43.9922C42.0193 43.9922 40.4005 43.4151 39.1016 42.4452C40.0457 40.8014 41.8075 39.7734 43.7734 39.7734C45.7394 39.7734 47.5012 40.8014 48.4453 42.4452C47.1463 43.4151 45.5275 43.9922 43.7734 43.9922ZM42.0547 35.9766V35.291C42.0547 34.3606 42.8258 33.6035 43.7734 33.6035C44.7211 33.6035 45.4922 34.3606 45.4922 35.291V35.9766C45.4922 36.907 44.7211 37.6641 43.7734 37.6641C42.8258 37.6641 42.0547 36.907 42.0547 35.9766ZM49.9975 40.9002C49.2052 39.7465 48.1073 38.8493 46.8325 38.2954C47.3384 37.6534 47.6406 36.8492 47.6406 35.9766V35.291C47.6406 33.1974 45.9059 31.4941 43.7734 31.4941C41.641 31.4941 39.9063 33.1974 39.9063 35.291V35.9766C39.9063 36.8492 40.2084 37.6534 40.7144 38.2954C39.4396 38.8493 38.3417 39.7465 37.5494 40.9002C36.601 39.6395 36.0391 38.0825 36.0391 36.3984C36.0391 32.2112 39.5087 28.8047 43.7734 28.8047C48.0382 28.8047 51.5078 32.2112 51.5078 36.3984C51.5078 38.0825 50.9459 39.6395 49.9975 40.9002Z"
    />
    <path
      d="M49.5742 67.1953H43.7734C43.1803 67.1953 42.6992 67.6675 42.6992 68.25C42.6992 68.8325 43.1803 69.3047 43.7734 69.3047H49.5742C50.1674 69.3047 50.6484 68.8325 50.6484 68.25C50.6484 67.6675 50.1674 67.1953 49.5742 67.1953Z"
    />
    <path
      d="M49.5742 61.2891H34.3203C33.7271 61.2891 33.2461 61.7612 33.2461 62.3438C33.2461 62.9263 33.7271 63.3984 34.3203 63.3984H49.5742C50.1674 63.3984 50.6484 62.9263 50.6484 62.3438C50.6484 61.7612 50.1674 61.2891 49.5742 61.2891Z"
    />
    <path
      d="M49.5742 55.3828H34.3203C33.7271 55.3828 33.2461 55.855 33.2461 56.4375C33.2461 57.02 33.7271 57.4922 34.3203 57.4922H49.5742C50.1674 57.4922 50.6484 57.02 50.6484 56.4375C50.6484 55.855 50.1674 55.3828 49.5742 55.3828Z"
    />
    <path
      d="M30.4909 49.7856C30.2912 49.5894 30.0141 49.4766 29.7305 49.4766C29.4478 49.4766 29.1707 49.5894 28.971 49.7856C28.7712 49.9818 28.6562 50.2539 28.6562 50.5312C28.6562 50.8086 28.7711 51.0807 28.971 51.2769C29.1719 51.4731 29.4478 51.5859 29.7305 51.5859C30.0141 51.5859 30.2901 51.4731 30.4909 51.2769C30.6907 51.0807 30.8058 50.8086 30.8058 50.5312C30.8058 50.2539 30.6908 49.9818 30.4909 49.7856Z"
    />
    <path
      d="M30.4909 55.6918C30.2901 55.4957 30.0141 55.3828 29.7305 55.3828C29.4478 55.3828 29.1719 55.4957 28.971 55.6918C28.7712 55.888 28.6562 56.1601 28.6562 56.4375C28.6562 56.7149 28.7711 56.987 28.971 57.1832C29.1719 57.3793 29.4478 57.4922 29.7305 57.4922C30.0141 57.4922 30.2901 57.3793 30.4909 57.1832C30.6907 56.987 30.8058 56.7149 30.8058 56.4375C30.8058 56.1601 30.6908 55.888 30.4909 55.6918Z"
    />
    <path
      d="M30.4909 61.5981C30.2901 61.4019 30.0141 61.2891 29.7305 61.2891C29.4478 61.2891 29.1719 61.4019 28.971 61.5981C28.7712 61.7943 28.6562 62.0664 28.6562 62.3438C28.6562 62.6211 28.7711 62.8932 28.971 63.0894C29.1707 63.2856 29.4478 63.3984 29.7305 63.3984C30.0141 63.3984 30.2912 63.2856 30.4909 63.0894C30.6907 62.8932 30.8058 62.6211 30.8058 62.3438C30.8058 62.0664 30.6908 61.7943 30.4909 61.5981Z"
    />
    <path
      d="M49.5742 49.4766H34.3203C33.7271 49.4766 33.2461 49.9487 33.2461 50.5312C33.2461 51.1138 33.7271 51.5859 34.3203 51.5859H49.5742C50.1674 51.5859 50.6484 51.1138 50.6484 50.5312C50.6484 49.9487 50.1674 49.4766 49.5742 49.4766Z"
    />
    <path
      d="M73.7436 34.7449C72.7 34.1533 71.4842 33.9962 70.3199 34.3023C69.1559 34.6086 68.1829 35.3416 67.5804 36.3663L54.0623 59.3545C53.9882 59.4806 53.9414 59.6203 53.925 59.7649L52.9734 68.1332C52.9262 68.5496 53.1343 68.9541 53.5039 69.1637C53.6707 69.2582 53.856 69.305 54.0409 69.305C54.2656 69.305 54.4895 69.2359 54.678 69.0995L61.5835 64.1062C61.7029 64.02 61.8028 63.9104 61.8768 63.7843L75.3948 40.7961C76.6388 38.6808 75.898 35.9662 73.7436 34.7449ZM55.381 65.9714L55.8779 61.6017L58.9869 63.364L55.381 65.9714ZM60.5533 61.8162L56.46 59.4959L67.308 41.0483L71.4013 43.3686L60.5533 61.8162ZM73.5343 39.7413L72.4754 41.5419L68.3821 39.2216L69.441 37.4209C69.7566 36.8842 70.2662 36.5003 70.8759 36.3399C71.4859 36.1794 72.1227 36.2618 72.6692 36.5717C73.2159 36.8816 73.6069 37.3819 73.7703 37.9806C73.9337 38.5792 73.8499 39.2045 73.5343 39.7413Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '96'
    },
    height: {
      type: String,
      default: '96'
    }
  }
}
</script>
