<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`stroke-current text-${color}`" viewBox="0 0 536 773" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      :opacity="opacity"
      d="M179.2 566.87V669.75M179.2 566.87L268.3 515.43M179.2 566.87V463.98M179.2 566.87L268.3 618.31M179.2 566.87L90.1 515.43M179.2 566.87L90.1 618.31M179.2 669.75L268.3 721.2M179.2 669.75L90.1 618.31M179.2 669.75L268.3 618.31M179.2 669.75L90.1 721.2L1 669.75V566.87M268.3 721.2L357.4 669.75M268.3 721.2V618.31M268.3 721.2L357.4 772.64L446.5 721.2M357.4 669.75V566.87M357.4 669.75L268.3 618.31M357.4 669.75L446.5 618.31M357.4 669.75L446.5 721.2M357.4 566.87L268.3 515.43M357.4 566.87L268.3 618.31M357.4 566.87V463.98M357.4 566.87L446.5 515.43M357.4 566.87L446.5 618.31M268.3 515.43L179.2 463.98M268.3 515.43L357.4 463.98M268.3 515.43V618.31M268.3 515.43V412.54M179.2 463.98L268.3 412.54M179.2 463.98V361.1M179.2 463.98L90.1 515.43M179.2 463.98L90.1 412.54M357.4 463.98L268.3 412.54M357.4 463.98V361.1M357.4 463.98L446.5 515.43M357.4 463.98L446.5 412.54M268.3 412.54L179.2 361.1M268.3 412.54L357.4 361.1M268.3 412.54V309.66M179.2 361.1L268.3 309.66M179.2 361.1V258.22M179.2 361.1L90.1 412.54M357.4 361.1L268.3 309.66M357.4 361.1V258.22M357.4 361.1L446.5 412.54M357.4 361.1L446.5 309.66M268.3 309.66L179.2 258.22M268.3 309.66L357.4 258.22M268.3 309.66V206.77M179.2 258.22L268.3 206.77M179.2 258.22L90.1 309.66V412.54M357.4 258.22L268.3 206.77M357.4 258.22L446.5 309.66M357.4 258.22L446.5 206.77M357.4 258.22V155.33M268.3 206.77L357.4 155.33M90.1 515.43V618.31M90.1 515.43V412.54M90.1 515.43L1 566.87M90.1 618.31L1 566.87M90.1 412.54L1 463.98V566.87M446.5 618.31V515.43M446.5 618.31L535.6 566.87M446.5 618.31V721.2M446.5 515.43V412.54M446.5 515.43L535.6 566.87M446.5 515.43L535.6 463.98M446.5 412.54V309.66M446.5 412.54L535.6 361.1M446.5 412.54L535.6 463.98M446.5 309.66L535.6 258.22M446.5 309.66V206.77M446.5 309.66L535.6 361.1M535.6 361.1V258.22M535.6 361.1V463.98M535.6 258.22L446.5 206.77M535.6 258.22V155.33M446.5 206.77L357.4 155.33M446.5 206.77L535.6 155.33M357.4 155.33L446.5 103.89L535.6 155.33M357.4 155.33V52.45L446.5 1L535.6 52.45V155.33M446.5 721.2L535.6 669.75V566.87M535.6 566.87V463.98"
      stroke-width="0.5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
<script>
export default {
  props: {
    opacity: {
      type: String,
      default: '0.7'
    },
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '536'
    },
    height: {
      type: String,
      default: '773'
    }
  }
}
</script>
