<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    :height="`${height}px`"
    :width="`${width}px`"
    :class="`fill-current text-${color}`"
  >
    <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M92 510 c-42 -26 -72 -76 -72 -121 0 -45 35 -99 77 -119 18 -8 33
-18 33 -21 0 -4 -16 -30 -35 -58 -55 -83 -30 -107 61 -57 91 50 144 140 144
246 0 113 -116 186 -208 130z"
      />
      <path
        d="M430 523 c-23 -9 -60 -45 -76 -75 -32 -62 -2 -148 63 -178 18 -8 33
-18 33 -21 0 -4 -16 -30 -35 -58 -40 -58 -42 -66 -18 -80 26 -14 129 43 165
93 32 45 58 123 58 175 0 100 -101 176 -190 144z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#58595B'
    },
    width: {
      type: String,
      default: '64'
    },
    height: {
      type: String,
      default: '64'
    }
  }
}
</script>
