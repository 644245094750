<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4766 9V9.02344H16.5H17.25C18.0392 9.0243 18.7958 9.33818 19.3538 9.89621C19.9118 10.4542 20.2257 11.2108 20.2266 12V20.25C20.2257 21.0392 19.9118 21.7958 19.3538 22.3538C18.7958 22.9118 18.0392 23.2257 17.25 23.2266H6.75003C5.96085 23.2257 5.20424 22.9118 4.64621 22.3538C4.08818 21.7958 3.7743 21.0392 3.77344 20.25L3.77344 12C3.7743 11.2108 4.08818 10.4542 4.64621 9.89621C5.20424 9.33818 5.96085 9.0243 6.75003 9.02344H7.5H7.52344V9V5.25C7.52344 4.06274 7.99507 2.92411 8.83459 2.08459C9.67411 1.24507 10.8127 0.773438 12 0.773438C13.1873 0.773438 14.3259 1.24507 15.1654 2.08459C16.0049 2.92411 16.4766 4.06274 16.4766 5.25V9ZM15 9.02344H15.0234V9V5.25C15.0234 4.44813 14.7049 3.67911 14.1379 3.11211C13.5709 2.5451 12.8019 2.22656 12 2.22656C11.1981 2.22656 10.4291 2.5451 9.86211 3.11211C9.2951 3.67911 8.97656 4.44813 8.97656 5.25V9V9.02344H9H15Z"
      stroke-width="0.046875"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
