<template>
  <svg
    :height="`${height}px` || '20px'"
    :width="`${width}px` || '20px'"
    viewBox="0 0 17 15"
  >
    <path
      d="M15.9 12H15.4C15.5 11.9 15.5 11.7 15.5 11.6V3.2C15.5 2.7 15.1 2.3 14.6 2.3H13.3V2.1C13.3 1.9 13.1 1.7 12.9 1.7H12.5V0.4C12.5 0.3 12.4 0.1 12.3 0.1C12.2 0 12 0 11.9 0L11.1 0.3C11 0.3 10.9 0.5 11 0.6C11 0.7 11.2 0.8 11.3 0.7L12 0.5V6.3L8.59999 7.5V1.7L10.4 1.1C10.5 1.1 10.6 0.9 10.5 0.8C10.5 0.7 10.3 0.6 10.2 0.7L8.39999 1.3L4.79999 0.1C4.69999 0.1 4.49999 0.1 4.39999 0.2C4.29999 0.3 4.2 0.4 4.2 0.5V1.7H3.79999C3.59999 1.7 3.39999 1.9 3.39999 2.1V2.4H2.09999C1.59999 2.4 1.2 2.8 1.2 3.3V4.2C1.2 4.3 1.29999 4.4 1.39999 4.4C1.49999 4.4 1.59999 4.3 1.59999 4.2V3.3C1.59999 3.1 1.8 2.9 2 2.9H3.29999V3.4H2.29999C2.19999 3.4 2.19999 3.4 2.09999 3.5C2.09999 3.5 2 3.6 2 3.7V11.4C2 11.5 2.1 11.6 2.2 11.6H14.1C14.2 11.6 14.2 11.6 14.3 11.5C14.3 11.5 14.4 11.4 14.4 11.3V3.6C14.4 3.5 14.3 3.4 14.2 3.4H13.2V2.9H14.5C14.7 2.9 14.9 3.1 14.9 3.3V11.7C14.9 11.9 14.7 12.1 14.5 12.1H1.89999C1.69999 12.1 1.5 11.9 1.5 11.7V5.2C1.5 5.1 1.39999 5 1.29999 5C1.19999 5 1.09999 5.1 1.09999 5.2V11.7C1.09999 11.8 1.1 12 1.2 12.1H0.699997C0.299997 12.1 0 12.4 0 12.8V13.5C0 13.9 0.299997 14.2 0.699997 14.2H10.3C10.4 14.2 10.5 14.1 10.5 14C10.5 13.9 10.4 13.8 10.3 13.8H0.699997C0.599997 13.8 0.5 13.7 0.5 13.6V12.9C0.5 12.8 0.599997 12.7 0.699997 12.7H6.59999V12.9C6.59999 13.2 6.9 13.5 7.2 13.5H9.2C9.5 13.5 9.79999 13.2 9.79999 12.9V12.7H15.7C15.8 12.7 15.9 12.8 15.9 12.9V13.6C15.9 13.7 15.8 13.8 15.7 13.8H11.3C11.2 13.8 11.1 13.9 11.1 14C11.1 14.1 11.2 14.2 11.3 14.2H15.7C16.1 14.2 16.4 13.9 16.4 13.5V12.8C16.6 12.3 16.3 12 15.9 12ZM12.2 6.8C12.4 6.7 12.5 6.6 12.5 6.4V2.1H12.8V7.6H9.7L12.2 6.8ZM4.7 0.5L8.09999 1.7V7.5L4.7 6.3V0.5ZM3.89999 2.1H4.2V6.3C4.2 6.5 4.3 6.6 4.5 6.7L6.89999 7.5H3.79999V2.1H3.89999ZM14.1 3.8V11H2.7V3.8H3.39999V7.7C3.39999 7.9 3.59999 8.1 3.79999 8.1H12.9C13.1 8.1 13.3 7.9 13.3 7.7V3.8H14.1ZM9.5 12.7C9.5 12.8 9.39999 12.8 9.39999 12.8H7.39999C7.29999 12.8 7.29999 12.7 7.29999 12.7V12.5H9.59999V12.7H9.5Z"
      :fill="color"
    />
    <path
      d="M11.5 8.8H5.2C5.1 8.8 5 8.9 5 9C5 9.1 5.1 9.2 5.2 9.2H11.5C11.6 9.2 11.7 9.1 11.7 9C11.8 8.9 11.7 8.8 11.5 8.8Z"
      :fill="color"
    />
    <path
      d="M9.29999 9.8H5.2C5.1 9.8 5 9.9 5 10C5 10.1 5.1 10.2 5.2 10.2H9.29999C9.39999 10.2 9.5 10.1 9.5 10C9.6 9.9 9.39999 9.8 9.29999 9.8Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    }
  }
}
</script>
