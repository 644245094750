<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.58555 13.3093L5.58563 13.3093L11.6457 16.7729C11.7536 16.8346 11.8757 16.867 12 16.867C12.1243 16.867 12.2464 16.8346 12.3543 16.7729L18.4144 13.3093L18.4145 13.3093C18.4638 13.2807 18.5199 13.2658 18.5769 13.2658C18.6339 13.2658 18.6899 13.2808 18.7392 13.3094C18.7885 13.3379 18.8295 13.3789 18.8579 13.4283C18.8864 13.4777 18.9013 13.5338 18.9012 13.5908V13.5908V16.8454V16.8454C18.9013 16.9651 18.8694 17.0827 18.8087 17.1859C18.7481 17.289 18.6609 17.3741 18.5563 17.4322L12.3257 20.8937C12.2261 20.949 12.114 20.9781 12 20.9781C11.886 20.9781 11.774 20.949 11.6743 20.8937L5.44375 17.4322L5.43324 17.4512L5.44375 17.4322C5.33911 17.3741 5.25194 17.289 5.19128 17.1859C5.13062 17.0827 5.09868 16.9651 5.09878 16.8454V16.8454L5.09878 13.5908L5.09878 13.5908C5.0987 13.5338 5.11363 13.4777 5.14208 13.4283C5.17053 13.3789 5.21148 13.3379 5.26083 13.3094C5.31018 13.2808 5.36617 13.2658 5.42317 13.2658C5.48018 13.2658 5.53618 13.2807 5.58555 13.3093Z"
      stroke-width="0.0432679"
    />
    <path
      d="M22.3812 9.16564V9.16088C22.37 9.05114 22.3327 8.94567 22.2725 8.85325C22.2123 8.76083 22.1309 8.68413 22.035 8.62955L12.343 3.09126C12.2384 3.03146 12.12 3 11.9995 3C11.879 3 11.7605 3.03146 11.6559 3.09126L1.96391 8.62955C1.85798 8.69011 1.76994 8.77759 1.70871 8.88314C1.64748 8.98868 1.61523 9.10852 1.61523 9.23054C1.61523 9.35256 1.64748 9.47241 1.70871 9.57795C1.76994 9.68349 1.85798 9.77097 1.96391 9.83153L11.6559 15.3698C11.7605 15.4296 11.879 15.4611 11.9995 15.4611C12.12 15.4611 12.2384 15.4296 12.343 15.3698L20.8703 10.4974C20.8834 10.4898 20.8984 10.4858 20.9136 10.4858C20.9288 10.4858 20.9438 10.4899 20.9569 10.4975C20.9701 10.5051 20.981 10.5161 20.9886 10.5293C20.9962 10.5425 21.0001 10.5575 21.0001 10.5727V16.8262C21.0001 17.1988 21.2865 17.5185 21.659 17.5371C21.7526 17.5416 21.8462 17.5271 21.934 17.4944C22.0218 17.4617 22.1021 17.4115 22.1699 17.3468C22.2378 17.2822 22.2918 17.2044 22.3287 17.1183C22.3656 17.0321 22.3846 16.9394 22.3846 16.8457V9.23054C22.3846 9.20886 22.3834 9.1872 22.3812 9.16564Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
