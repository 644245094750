<template>
  <svg :height="`${height}px`" :width="`${width}px`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H18C19.1046 16 20 15.1046 20 14V6C20 4.89543 19.1046 4 18 4H4ZM11 13C12.6569 13 14 11.6569 14 10C14 8.34315 12.6569 7 11 7C9.34315 7 8 8.34315 8 10C8 11.6569 9.34315 13 11 13Z"
      :fill="`${color}`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.25 7C22.6642 7 23 7.33579 23 7.75V14.25C23 15.467 22.6566 16.6623 21.8505 17.5628C21.0318 18.4772 19.814 19 18.25 19H5.75C5.33579 19 5 18.6642 5 18.25C5 17.8358 5.33579 17.5 5.75 17.5H18.25C19.4638 17.5 20.246 17.1061 20.7329 16.5622C21.2323 16.0044 21.5 15.1997 21.5 14.25V7.75C21.5 7.33579 21.8358 7 22.25 7Z"
      :fill="`${color}`"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#F7931E'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
