<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`stroke-current text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1287 9V15.743C10.1365 16.236 10.3378 16.7063 10.6893 17.0522C11.0407 17.3982 11.5141 17.592 12.0072 17.592C12.5004 17.592 12.9737 17.3982 13.3252 17.0522C13.6766 16.7063 13.878 16.236 13.8857 15.743L13.8918 6.90469C13.8969 6.48325 13.8184 6.06499 13.6606 5.67415C13.5029 5.28331 13.2691 4.92767 12.9729 4.62784C12.6767 4.32801 12.3239 4.08996 11.935 3.92749C11.5461 3.76502 11.1289 3.68136 10.7074 3.68136C10.2859 3.68136 9.86863 3.76502 9.47974 3.92749C9.09084 4.08996 8.73806 4.32801 8.44185 4.62784C8.14564 4.92767 7.91189 5.28331 7.75415 5.67415C7.59641 6.06499 7.51782 6.48325 7.52293 6.90469V15.8025C7.51434 16.3958 7.62379 16.9849 7.8449 17.5356C8.06602 18.0862 8.39439 18.5874 8.81093 19.01C9.22747 19.4326 9.72386 19.7682 10.2713 19.9973C10.8186 20.2264 11.4061 20.3443 11.9995 20.3443C12.5929 20.3443 13.1803 20.2264 13.7277 19.9973C14.2751 19.7682 14.7715 19.4326 15.1881 19.01C15.6046 18.5874 15.933 18.0862 16.1541 17.5356C16.3752 16.9849 16.4846 16.3958 16.4761 15.8025V7.48781"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
