<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" :height="`${height}px` || '24px'" :width="`${width}px` || '24px'" :class="`fill-current  text-${color}`" viewBox="0 0 21 14">
    <path
      d="M19.275 12.4165C19.086 12.4164 18.8992 12.3766 18.7266 12.2996C18.6864 12.2818 18.6481 12.2601 18.6122 12.2347L15.1229 9.77852C14.9459 9.65395 14.8015 9.48866 14.7018 9.29659C14.6021 9.10452 14.55 8.8913 14.55 8.67489V5.3252C14.55 5.1088 14.6021 4.89557 14.7018 4.7035C14.8015 4.51143 14.9459 4.34615 15.1229 4.22158L18.6122 1.76542C18.6481 1.74005 18.6864 1.71828 18.7266 1.70046C18.9321 1.60907 19.1572 1.5705 19.3815 1.58824C19.6057 1.60597 19.8219 1.67946 20.0106 1.80202C20.1992 1.92458 20.3542 2.09232 20.4615 2.29002C20.5688 2.48771 20.625 2.70908 20.625 2.93402V11.0661C20.625 11.4241 20.4828 11.7675 20.2296 12.0207C19.9764 12.2738 19.633 12.4161 19.275 12.4161V12.4165ZM11.0063 13.075H3.24375C2.48315 13.0743 1.75393 12.7718 1.2161 12.2339C0.678275 11.6961 0.375782 10.9669 0.375 10.2063V3.7938C0.375782 3.0332 0.678275 2.30398 1.2161 1.76615C1.75393 1.22832 2.48315 0.925831 3.24375 0.925049H11.0265C11.7817 0.925942 12.5057 1.22634 13.0397 1.76034C13.5737 2.29435 13.8741 3.01835 13.875 3.77355V10.2063C13.8742 10.9669 13.5717 11.6961 13.0339 12.2339C12.4961 12.7718 11.7669 13.0743 11.0063 13.075Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '21'
    },
    height: {
      type: String,
      default: '14'
    }
  }
}
</script>
