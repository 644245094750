<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 70 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.12999 48.6787C4.46664 41.5918 7.23655 34.8376 11.9727 29.5549C16.7089 24.2723 23.1217 20.7842 30.13 19.6787C31.04 16.6787 32.5 15.2787 34.82 15.3587C37.14 15.4387 38.55 16.8087 39.23 19.5487C54.85 23.1187 63.55 32.7787 65.46 48.7787H67.9C68.99 48.7787 69.41 49.2187 69.42 50.3187C69.42 52.1087 69.42 53.8987 69.42 55.6987C69.42 56.8287 68.99 57.2387 67.83 57.2587H2C0.32 57.2587 0 56.9587 0 55.2587C0 53.5587 0 51.8987 0 50.2587C0 49.2587 0.440001 48.7787 1.47 48.7587H3.89999C3.96999 48.7687 4.01999 48.7287 4.12999 48.6787ZM62.78 48.7387C62.6506 44.0586 61.3197 39.4903 58.9153 35.473C56.5108 31.4556 53.1136 28.1242 49.05 25.7987C38.94 20.0387 28.73 20.3087 19.05 26.7187C11.24 31.8587 7.29999 39.3987 6.59999 48.7187L62.78 48.7387ZM2.48 54.7387H66.95V51.2387H2.48V54.7387ZM36.64 19.3887C36.5733 18.9126 36.3312 18.4786 35.9613 18.1716C35.5914 17.8645 35.1202 17.7066 34.64 17.7287C34.1963 17.7357 33.7717 17.911 33.4524 18.2192C33.1331 18.5274 32.9428 18.9455 32.92 19.3887H36.64Z"
    />
    <path
      d="M28.0094 11.8181C27.5994 11.5081 27.1394 11.3282 27.0094 10.9982C26.9177 10.8115 26.8699 10.6062 26.8699 10.3982C26.8699 10.1901 26.9177 9.98489 27.0094 9.79819C27.4465 9.23642 27.6838 8.54493 27.6838 7.83316C27.6838 7.12139 27.4465 6.42997 27.0094 5.8682C26.556 5.24523 26.285 4.5083 26.2267 3.74002C26.1685 2.97174 26.3252 2.20239 26.6794 1.51816C26.9938 1.00386 27.4088 0.558306 27.8994 0.208161C28.0087 0.120092 28.1363 0.0576318 28.2728 0.0252996C28.4093 -0.00703262 28.5514 -0.00839683 28.6885 0.0213323C28.8257 0.0510614 28.9544 0.111139 29.0653 0.197114C29.1762 0.283088 29.2665 0.392757 29.3294 0.518158C29.5394 0.898158 29.6294 1.68816 29.3994 1.89816C28.2194 2.99816 28.7194 4.00816 29.3394 5.07816C29.9125 5.97317 30.1916 7.02483 30.1378 8.08621C30.084 9.14759 29.7 10.1657 29.0394 10.9982C28.7256 11.3066 28.3804 11.5814 28.0094 11.8181Z"
    />
    <path
      d="M41.2803 11.6377C40.0603 11.6377 39.4503 10.5577 40.0403 9.7777C40.4757 9.231 40.7169 8.55505 40.7259 7.85625C40.735 7.15745 40.5114 6.4755 40.0903 5.91771C39.6614 5.33278 39.3917 4.64651 39.3076 3.92607C39.2234 3.20564 39.3278 2.47575 39.6103 1.80773C39.9258 1.19701 40.3692 0.661498 40.9103 0.23772C41.0179 0.148104 41.143 0.0820175 41.2776 0.0438115C41.4123 0.00560563 41.5535 -0.00387586 41.692 0.0159185C41.8306 0.0357128 41.9634 0.0843606 42.082 0.158741C42.2006 0.233121 42.3022 0.331545 42.3803 0.447681C42.4903 0.535717 42.5805 0.64605 42.6449 0.771351C42.7093 0.896651 42.7464 1.03412 42.754 1.17479C42.7615 1.31547 42.7393 1.45616 42.6887 1.58763C42.6381 1.71911 42.5603 1.83843 42.4603 1.93773C41.2903 2.93773 41.7603 3.9377 42.3703 5.0277C42.9546 5.91504 43.2466 6.96295 43.2054 8.02459C43.1641 9.08622 42.7917 10.1084 42.1403 10.9477C41.884 11.2131 41.5951 11.445 41.2803 11.6377Z"
    />
    <path
      d="M34.7318 11.6383C33.5818 11.6383 32.9518 10.5783 33.5218 9.82834C34.5818 8.41834 34.3018 7.12837 33.5218 5.73837C33.0842 5.1008 32.8344 4.35317 32.8008 3.58059C32.7672 2.80801 32.9512 2.04152 33.3318 1.36837C33.5942 0.951461 33.9336 0.588319 34.3318 0.298367C34.4475 0.196235 34.5835 0.119822 34.7309 0.074185C34.8783 0.0285478 35.0337 0.0146907 35.1868 0.0335967C35.34 0.0525026 35.4874 0.10375 35.6192 0.183865C35.7511 0.26398 35.8645 0.371184 35.9518 0.498379C36.1536 0.711894 36.2661 0.994543 36.2661 1.28836C36.2661 1.58217 36.1536 1.86482 35.9518 2.07834C35.1318 3.07834 35.0818 3.55836 35.8218 4.78836C36.5576 5.80816 36.8694 7.07342 36.6918 8.31833C36.4431 9.28577 36.07 10.2169 35.5818 11.0883C35.3518 11.3883 34.8818 11.5283 34.7318 11.6383Z"
    />
    <path
      d="M24.5004 28.6583C24.2068 29.0056 23.8709 29.3146 23.5004 29.5783C19.4628 31.8351 16.1742 35.2244 14.0404 39.3283C13.8504 39.6883 13.6904 40.0583 13.5004 40.4183C13.4533 40.587 13.3701 40.7434 13.2566 40.8768C13.1431 41.0102 13.002 41.1174 12.843 41.1909C12.684 41.2644 12.511 41.3026 12.3359 41.3027C12.1607 41.3028 11.9876 41.2649 11.8286 41.1916C11.6695 41.1183 11.5283 41.0114 11.4146 40.8781C11.3009 40.7449 11.2175 40.5886 11.1701 40.42C11.1227 40.2513 11.1125 40.0745 11.1402 39.9015C11.1679 39.7286 11.2328 39.5637 11.3304 39.4183C12.7957 36.0661 15.013 33.0961 17.8104 30.7383C19.344 29.517 20.9539 28.3946 22.6304 27.3783C23.5104 26.7383 24.5204 27.3283 24.5004 28.6583Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '70'
    },
    height: {
      type: String,
      default: '58'
    }
  }
}
</script>
