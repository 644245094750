<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          :fill="color"
          d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"
        />
      </g>
    </g>
    <g>
      <g>
        <rect x="76" y="230" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="156" y="230" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="236" y="230" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="316" y="230" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="396" y="230" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="76" y="310" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="156" y="310" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="236" y="310" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="316" y="310" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="76" y="390" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="156" y="390" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="236" y="390" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="316" y="390" width="40" height="40" :fill="color" />
      </g>
    </g>
    <g>
      <g>
        <rect x="396" y="310" width="40" height="40" :fill="color" />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    }
  }
}
</script>
