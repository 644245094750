<template>
  <object type="image/svg+xml" :data="srcOriginal" class="logo" />
  <!-- <img
    :src="src.toString()"
    alt="logo"
    class="logo"
  /> -->
</template>
<script>
import LOGO from '@/assets/images/G2AXtion.svg'
import LOGO_WHITE from '@/assets/images/G2AXtion-White.svg'
import LOGO_ORIGINAL from '@/assets/images/G2Logo.svg'
export default {
  data() {
    return {
      src: this.dark ? LOGO_WHITE : LOGO,
      srcOriginal: LOGO_ORIGINAL
    }
  },
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#F7931E'
    },
    width: {
      type: String,
      default: '174'
    },
    height: {
      type: String,
      default: '38'
    }
  }
}
</script>
<style lang="scss" scoped>
.logo {
  max-width: 210px;
  pointer-events: none;
  width: 210px;

  @media screen and (max-width: 680px) {
    max-width: unset;
    width: 170px;
  }
}
</style>
