<template>
  <svg :height="`${height}px`" :width="`${width}px`" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.4999 15.75C18.0959 15.7499 17.696 15.8316 17.3244 15.9903C16.9528 16.149 16.6173 16.3814 16.338 16.6734L9.40054 12.7702C9.53493 12.2655 9.53493 11.7345 9.40054 11.2299L16.338 7.32657C16.8432 7.85032 17.522 8.17192 18.2473 8.23111C18.9726 8.2903 19.6946 8.08302 20.278 7.6481C20.8614 7.21319 21.2662 6.58048 21.4166 5.8685C21.567 5.15653 21.4527 4.41415 21.095 3.78043C20.7373 3.14672 20.1609 2.66515 19.4736 2.42595C18.7864 2.18676 18.0355 2.20634 17.3616 2.48103C16.6878 2.75572 16.1372 3.26667 15.8131 3.91817C15.4889 4.56966 15.4134 5.317 15.6007 6.02016L8.6632 9.92344C8.2488 9.49137 7.7146 9.19316 7.12933 9.06718C6.54405 8.9412 5.93447 8.99321 5.379 9.21653C4.82353 9.43985 4.34758 9.82425 4.01237 10.3203C3.67717 10.8163 3.49805 11.4013 3.49805 12C3.49805 12.5987 3.67717 13.1837 4.01237 13.6797C4.34758 14.1758 4.82353 14.5602 5.379 14.7835C5.93447 15.0068 6.54405 15.0588 7.12933 14.9328C7.7146 14.8069 8.2488 14.5086 8.6632 14.0766L15.6007 17.9799C15.4399 18.5854 15.4726 19.2261 15.6945 19.8121C15.9163 20.3981 16.316 20.8999 16.8376 21.2472C17.3591 21.5944 17.9763 21.7696 18.6025 21.7483C19.2287 21.7269 19.8325 21.51 20.3291 21.128C20.8258 20.746 21.1904 20.2181 21.3717 19.6184C21.5531 19.0186 21.5421 18.3772 21.3404 17.784C21.1386 17.1908 20.7562 16.6757 20.2468 16.3109C19.7373 15.9461 19.1265 15.75 18.4999 15.75Z"
      :fill="`${color}`"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#58595B'
    },
    width: {
      type: String,
      default: '25'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
