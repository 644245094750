<template>
  <svg :height="`${height}px` || '20px'" :width="`${width}px` || '20px'" :class="`fill-current text-${color}`" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M54.2105 13H19.2632C18.4211 13 18.4211 14.3474 19.2632 14.3474H53.5368V41.8H41.0737L39.7263 40.4526H51.5158C51.8526 40.4526 52.1895 40.2 52.1895 39.7789V16.2842C52.1895 15.9474 51.9368 15.6105 51.5158 15.6105H13.2842C12.9474 15.6105 12.6105 15.8632 12.6105 16.2842V39.7789C12.6105 40.1158 12.8632 40.4526 13.2842 40.4526H25.0737L23.7263 41.8H11.3474V14.3474H15.8105C16.6526 14.3474 16.6526 13 15.8105 13H10.7579C10.2526 13 10 13.2526 10 13.6737V42.4737C10 42.8105 10.2526 43.1474 10.6737 43.1474H22.4632L20.2737 45.3368C19.8526 45.7579 20.1053 46.4316 20.6947 46.4316L23.4737 46.6842L23.7263 49.4632C23.8105 49.9684 24.4842 50.2211 24.8211 49.8842L30.2947 44.4105C31.5579 44.8316 33.1579 44.8316 34.5053 44.4105L35.5158 45.4211C36.1053 46.0105 37.0316 45.0842 36.4421 44.4947L35.7684 43.821C36.8632 43.1474 37.7895 42.2211 38.379 41.0421L42.5895 45.2526L40.6526 45.4211C40.3158 45.4211 40.0632 45.6737 40.0632 46.0105L39.8947 47.9474L38.6316 46.6C38.0421 46.0105 37.1158 46.9368 37.7053 47.5263L40.0632 49.8842C40.4842 50.3053 41.1579 50.0526 41.1579 49.4632L41.4105 46.6842L44.1895 46.4316C44.6947 46.3474 44.9474 45.6737 44.6105 45.3368L42.4211 43.1474H54.2105C54.5474 43.1474 54.8842 42.8947 54.8842 42.4737V13.6737C54.8 13.2526 54.5474 13 54.2105 13ZM13.9579 16.9579H50.9263V39.1895H39.0526C39.8105 35.1474 36.6105 31.1895 32.4 31.2737C28.2737 31.1895 24.9895 35.1474 25.7474 39.1895H13.9579V16.9579ZM24.9053 47.9474L24.7368 46.0105C24.7368 45.6737 24.4842 45.4211 24.1474 45.4211L22.2105 45.2526L26.4211 41.0421C27.0105 42.2211 27.9368 43.1474 29.0316 43.821L24.9053 47.9474ZM32.4 43.4C29.4526 43.4 27.0105 40.9579 27.0105 38.0105C27.3474 30.8526 37.5368 30.8526 37.8737 38.0105C37.8737 40.9579 35.4316 43.4 32.4 43.4Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    }
  }
}
</script>
