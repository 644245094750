<template>
  <div>
    <svg :height="`${height}px` || '8px'" :width="`${width}px` || '16px'" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.28344 4H10.7791H5.28344Z" :fill="`${color}`" />
      <path
        d="M6.27063 7H4.5C3.70435 7 2.94129 6.68393 2.37868 6.12132C1.81607 5.55871 1.5 4.79565 1.5 4C1.5 3.20435 1.81607 2.44129 2.37868 1.87868C2.94129 1.31607 3.70435 1 4.5 1H6.23156M9.76844 1H11.5C12.2956 1 13.0587 1.31607 13.6213 1.87868C14.1839 2.44129 14.5 3.20435 14.5 4C14.5 4.79565 14.1839 5.55871 13.6213 6.12132C13.0587 6.68393 12.2956 7 11.5 7H9.72937M5.28344 4H10.7791"
        :stroke="`${color}`"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#F7931E'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '8'
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
