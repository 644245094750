<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    :height="`${height}px` || '20px'"
    :width="`${width}px` || '20px'"
  >
    <path
      d="M11.2 14.5C11.1 14.5 11 14.4 11 14.3C11 14.2 11 14.2 11.1 14.1C11.3 14 11.4 13.9 11.6 13.8C11.7 13.7 11.8 13.8 11.9 13.9C12 14 11.9 14.1 11.8 14.2C11.6 14.3 11.4 14.4 11.2 14.5Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M8 15.2C4.1 15.2 1 12 1 8.1C1 4.2 4.20001 1.1 8.10001 1.1C12 1.1 15.1 4.3 15.1 8.2C15.1 9.4 14.8 10.5 14.3 11.5C14.2 11.6 14.1 11.6 14 11.6C13.9 11.5 13.9 11.4 13.9 11.3C15.6 8.1 14.4 4 11.2 2.3C8.00001 0.599998 3.90001 1.8 2.20001 5C0.500012 8.2 1.69999 12.3 4.89999 14C5.89999 14.5 7.00001 14.8 8.10001 14.8C8.70001 14.8 9.29999 14.7 9.79999 14.6C9.89999 14.6 10 14.6 10 14.7C10 14.8 9.99999 14.9 9.89999 14.9C9.19999 15.2 8.6 15.2 8 15.2Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M12.8 13.3C12.7 13.3 12.6 13.2 12.6 13.1C12.6 13 12.6 13 12.7 13C12.8 12.9 13 12.7 13.1 12.6C13.2 12.5 13.3 12.5 13.4 12.6C13.5 12.7 13.5 12.8 13.4 12.9C13.3 13.1 13.1 13.2 13 13.4C12.9 13.3 12.8 13.3 12.8 13.3Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M8.00003 13.7C4.90003 13.7 2.40002 11.2 2.40002 8.1C2.40002 5 4.90003 2.5 8.00003 2.5C11.1 2.5 13.6 5 13.6 8.1C13.6 11.2 11.1 13.7 8.00003 13.7ZM8.00003 3C5.10003 3 2.80002 5.3 2.80002 8.2C2.80002 11.1 5.10003 13.4 8.00003 13.4C10.9 13.4 13.2 11.1 13.2 8.2C13.2 5.3 10.9 3 8.00003 3Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M8 11.7C7.6 11.7 7.29999 11.4 7.29999 11V7.6C7.29999 7.2 7.6 6.9 8 6.9C8.4 6.9 8.70001 7.2 8.70001 7.6V11C8.70001 11.4 8.4 11.7 8 11.7ZM8 7.3C7.8 7.3 7.70001 7.4 7.70001 7.6V11C7.70001 11.2 7.8 11.3 8 11.3C8.2 11.3 8.29999 11.2 8.29999 11V7.6C8.29999 7.4 8.2 7.3 8 7.3Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M8 6.3C7.6 6.3 7.20001 5.9 7.20001 5.5C7.20001 5.1 7.6 4.7 8 4.7C8.4 4.7 8.79999 5.1 8.79999 5.5C8.79999 5.9 8.5 6.3 8 6.3ZM8 5C7.8 5 7.60001 5.2 7.60001 5.4C7.60001 5.6 7.8 5.8 8 5.8C8.2 5.8 8.39999 5.6 8.39999 5.4C8.39999 5.2 8.2 5 8 5Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    }
  }
}
</script>
