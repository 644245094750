<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`stroke-current text-${color}`" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 8C14 4.6875 11.3125 2 8 2C4.6875 2 2 4.6875 2 8C2 11.3125 4.6875 14 8 14C11.3125 14 14 11.3125 14 8Z" fill="white" stroke-miterlimit="10" />
    <path
      d="M6.58841 10.8241L6.58657 10.823C6.55269 10.8021 6.5 10.7356 6.5 10.6268V5.37324C6.5 5.26406 6.55248 5.19806 6.58657 5.17706L6.58658 5.17707L6.58925 5.1754C6.60071 5.16824 6.61002 5.1666 6.61669 5.16669C6.62339 5.16678 6.633 5.1687 6.64477 5.17649L6.64476 5.1765L6.64797 5.17859L10.773 7.86209C10.7861 7.87167 10.8008 7.88738 10.8127 7.91079C10.8255 7.93583 10.8333 7.96656 10.8333 7.99967C10.8333 8.03279 10.8255 8.06352 10.8127 8.08856C10.8008 8.11198 10.7861 8.12768 10.773 8.13727L6.64729 10.8215L6.64728 10.8215L6.6454 10.8227C6.63343 10.8306 6.62326 10.833 6.61556 10.8333C6.60852 10.8331 6.59931 10.8309 6.58841 10.8241Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
