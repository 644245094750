<template>
  <!-- <svg viewBox="0 0 24 24" :height="`${height}px`" :width="`${width}px`" fill="none" xmlns="http://www.w3.org/2000/svg" :class="`stroke-current text-${color}`">
    <path d="M19.5 6L9 18L4.5 13.5" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg> -->
  <svg :height="`${height}px`" :width="`${width}px`" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 2L8 18L2 12" :stroke="`${color}`" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
