<template>
  <svg
    :height="`${height}px` || '24px'"
    :width="`${width}px` || '24px'"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_19_165)">
      <path
        d="M24 12.0001C24 5.37265 18.6274 7.72476e-05 12 7.72476e-05C5.37258 7.72476e-05 0 5.37265 0 12.0001C0 17.9896 4.38823 22.9541 10.125 23.8543V15.4688H7.07813V12.0001H10.125V9.35633C10.125 6.34883 11.9165 4.68758 14.6576 4.68758C15.9705 4.68758 17.3438 4.92195 17.3438 4.92195V7.87508H15.8306C14.3399 7.87508 13.875 8.80008 13.875 9.74907V12.0001H17.2031L16.6711 15.4688H13.875V23.8543C19.6118 22.9541 24 17.9896 24 12.0001Z"
        fill="#1877F2"
      />
      <path
        d="M16.6711 15.4688L17.2031 12H13.875V9.74899C13.875 8.80001 14.3399 7.875 15.8306 7.875H17.3438V4.92188C17.3438 4.92188 15.9705 4.6875 14.6576 4.6875C11.9165 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4688H10.125V23.8542C10.7359 23.9501 11.3621 24 12 24C12.6379 24 13.2641 23.9501 13.875 23.8542V15.4688H16.6711Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_19_165">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
